<template>
  <div class="common-mask">
    <Dialog visible @close="onClose">
      <div class="flex items-center flex-col w-full">
        <img
          class="w-[44px] h-[44px] sm:mt-5 sm:w-[54px] sm:h-[54px]"
          src="@/assets/images/logo-icon.png"
          alt="logo"
        />
        <img
          class="mt-4 mb-4 sm:mb-10 h-6"
          src="@/assets/images/logo-text-white.png"
          alt="white logo"
        />
        <div class="iv-form">
          <div class="iv-form-item">
            <div class="iv-form-item-label">{{ $t('login-label-email') }}</div>
            <div class="iv-form-item-box">
              <div class="iv-form-item-input">
                <input
                  type="email"
                  :placeholder="$t('email')"
                  v-model="form.email"
                />
              </div>
            </div>
            <div class="iv-err-tip" v-if="error.email">！{{ error.email }}</div>
          </div>
          <div class="iv-form-item">
            <div class="iv-form-item-label">{{ $t('login-label-password') }}</div>
            <div class="iv-form-item-box">
              <div class="iv-form-item-input">
                <input
                  type="password"
                  :placeholder="$t('password')"
                  v-model="form.password"
                  :maxlength="25"
                  @keyup.enter="submit"
                />
              </div>
            </div>
            <!-- <div class="iv-err-tip" v-if="error.password">{{ error.password }}</div> -->
          </div>
        </div>
        <div class="flex justify-end w-full mt-3">
          <!-- <span @click="onSignUp">{{ $t("login-sign-up") }}</span> -->
          <span
            class="font-Roboto-Bold font-bold  text-[12px] leading-[14px] cursor-pointer"
            @click="onForgot"
            >{{ $t("login-forgot-txt") }}</span
          >
        </div>
        <div
          class="mt-5 h-10 rounded-full w-full font-Roboto-Bold font-bold  text-base leading-10 text-white text-center "
          :class="disable ? ' bg-btn-gray cursor-not-allowed' : 'bg-mainColor cursor-pointer'"
          @click="submit"
        >
        <icon-loading v-show="loading" class="mr-3"/>{{ $t("login-login") }}
        </div>
        
      </div>
      <template #footer>
          <div class="mt-6 sm:mt-[30px]" >
          <p class="font-Roboto-Bold font-bold  text-text-deep text-xs text-center">
            {{ $t('login-or') }}
          </p>
          <div class="mt-2 sm:mt-5 flex items-center justify-center gap-10">
            <img
              @click="loginByGoogle"
              :src="googleIcon"
              alt="google"
              height="50"
              width="50"
            />
            <img
              @click="loginFacebook"
              :src="facebookIcon"
              alt="facebook"
              height="50"
              width="50"
            />
            <img
              @click="loginByApple"
              :src="appleIcon"
              alt="apple"
              height="50"
              width="50"
            />
          </div>
          <p class="text-text-deep text-xs mt-6">
            {{ $t('login-no-account1') }}
            <span @click="onSignUp" class="text-mainColor cursor-pointer"
              >{{ $t('login-no-account2') }}</span
            >
          </p>
          </div>
        </template>
    </Dialog>
  </div>
</template>
<script setup>
import messageBox from "@/composables/messageBox";
import { useMainStore } from "@/store/index";
import { termsofService, privacyPolicy } from "@/utils/config";
import { publicKey } from "@/utils/config";
import { verifyEmail, verifyPw } from "@/utils/verify";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider,
  browserPopupRedirectResolver,
} from "firebase/auth";
const googleIcon =
  "data:image/webp;base64,UklGRpYCAABXRUJQVlA4WAoAAAAQAAAAMQAAMQAAQUxQSOUAAAABgBDbbhXoSYiESEFKHCwOFgfEAThAQiW0DiKhErJfaB8GImICMFDNa/TMjOKmmC+fkQ/Ddc5Scuih4/TK4YcO+ug5MWzInpP3Z9JyepUH0pKwyr2WlPXWnqT7DUva9R8Nnq5/nUl8/aFJvfw6uQoATXIBnG0Dgi2gSa/GZ87nha8GXyR/f8Er+wuCLypfcT43PlM+RbAFsLE5IGwKoHAdALBw6S9cTAf+1M4T+hdWHsP/zuK4WzkabktlaHIPUuc1wWOf5RhpMaOvGKvnuEsxXM8xZcFU9XgSm2C+mpfIzB7VTTEQAFZQOCCKAQAAcAkAnQEqMgAyAD6RRJ1LJaOioaIKqLASCWIA0p+xJjhU8ADbAZ7N1gG8r/tz6MyeBQUe13My5jbiv5RJZDZC1qYTAbpWuK4U9u3vT+Jm9JjLbnAAAP78SgkCQ0cbP1A+elae4tuyY2hp9SET0jHYKcqz/VzAI5j866NsK3f/GFiZvdwBbZ///OBoTT5U1HXL02ntJ1IaDt0QG417A1L+c+XnUkO8CJD/jDRMv4ftlBFQ1ugmx88t3ZJ8zIANKOhHwZZo8RP/JaVI0OqrhTi+nT/iBd7o1JXqMGNRd/zrv313lAum9r+8w3en+fI2K2mwxonicWF/Xh3CVvjEauzPnF0COnlexS8iipyr4bXuMhBKrCaQRLkX7VEiH93qiyDUxnz6F2kr+ho2GRX3BO6RCEoW14EB6bRV3ycEyiDxd3v1OpBKOD0z58gdbQU+ZVJqyaHbn6hCiI7ILO+yCtSbup0zC5elSMQ3ngQTLcyVwOrx/+k0H//83BOU///nASZ/6UP//5uAAAAAAA==";
const appleIcon =
  "data:image/webp;base64,UklGRhICAABXRUJQVlA4WAoAAAAQAAAAMQAAMQAAQUxQSOUAAAABgBDbbhXoSYiESEFKHCwOFgfEAThAQiW0DiKhErJfaB8GImICMFDNa/TMjOKmmC+fkQ/Ddc5Scuih4/TK4YcO+ug5MWzInpP3Z9JyepUH0pKwyr2WlPXWnqT7DUva9R8Nnq5/nUl8/aFJvfw6uQoATXIBnG0Dgi2gSa/GZ87nha8GXyR/f8Er+wuCLypfcT43PlM+RbAFsLE5IGwKoHAdALBw6S9cTAf+1M4T+hdWHsP/zuK4WzkabktlaHIPUuc1wWOf5RhpMaOvGKvnuEsxXM8xZcFU9XgSm2C+mpfIzB7VTTEQAFZQOCAGAQAAcAYAnQEqMgAyAD6RQptKJaOiIaINULASCWkABRAhinjERQDZYRr+FAkJWGsKTrypwStqXXpH9oo8smmYAP77IZSfwR/3DZGMqr9in/7luIPt/440LxkLHlNmFUBP//2/yXSj4f9IsZQKZ3HhqfpfYXKiXfXYuxJ/jn7EpAMdzp+4ZSpoT/X5Kbxwtm4HewWD//A/vPzl24B8S/U+GRXeRDkaP85G4Ir1Q7SyC0W7MDQSbausvsUljVA0+2Ftk5bD23BLWQfs5ULL6EmO2ldSOmv9vzg1gvgrumeefVVgqPjNQcbitAh6iH8CVISt9JI/8Ujf//t3zn//+3+V/9lX//7dsAAAAA==";
const facebookIcon =
  "data:image/webp;base64,UklGRh4CAABXRUJQVlA4WAoAAAAQAAAAMQAAMQAAQUxQSOUAAAABgBDbbhXoSYiESEFKHCwOFgfEAThAQiW0DiKhErJfaB8GImICMFDNa/TMjOKmmC+fkQ/Ddc5Scuih4/TK4YcO+ug5MWzInpP3Z9JyepUH0pKwyr2WlPXWnqT7DUva9R8Nnq5/nUl8/aFJvfw6uQoATXIBnG0Dgi2gSa/GZ87nha8GXyR/f8Er+wuCLypfcT43PlM+RbAFsLE5IGwKoHAdALBw6S9cTAf+1M4T+hdWHsP/zuK4WzkabktlaHIPUuc1wWOf5RhpMaOvGKvnuEsxXM8xZcFU9XgSm2C+mpfIzB7VTTEQAFZQOCASAQAA0AcAnQEqMgAyAD6RRJ1LpaOioaIKILASCUEOADKNUCcAbABtMGSAc9r7KpEUI9/btvICNf+Y7hjkWRzkFdTJ0Xhowm1g0AAA/sxwco1uinRZUh7XIADNWrbZGoxl9670ckN/b3E+Tiiq+DZsyxmz6Pf//y91CR9y0onsk9EhbWIIwbq0MLxD/B0LGrmB53g+aW9rFFPnw1JEytUgDqu/+u/Kj8CBFYr2wSYc6VaKISVwmBiCQWo9trEqPBwD/9JMDfnO16kIB3EJHf4flrs3v/Qf+Wl8XZVn4CPHn0P+dhByOC5B+MVxzPX0BMOazUxHB/FZvRIYy2m8eKoZyHJ1P//+XJt0///L2Hnow///lyAAAA==";
const mainStore = useMainStore();
const { t } = useI18n();
const { $firebaseApp } = useNuxtApp();
const LoginType = {
  apple: "apple",
  google: "google",
  facebook: "facebook",
};

const fullLoading = useFullLoading();
const { $encryptor } = useNuxtApp();
const form = ref({ email: "", password: "" });
const error = ref({ email: "", password: "" });
const loading = ref(false);
// const visibleEmailLogin = computed(() => mainStore.visibleEmailLogin);
// const vipToLogin = computed(() => mainStore.vipToLogin);
const disable = computed(() => {
  if (!form.value.password.trim() || !form.value.email.trim()) {
    return true;
  }
  return false;
});

watch(mainStore.visibleEmailLogin, (n, o) => {
  if (n) {
    form.value.email = "";
    form.value.password = "";
    error.value.email = "";
    error.value.password = "";
  }
});
// function onClose() {
//   useLogEvent({ eventName: "login-close" });
//   useFBLogEvent({ eventName: "login-close" })
//   mainStore.setVisibleLogin(false);
//   mainStore.setVisibleLogin(true);
// }
function onSignUp() {
  mainStore.setVisibleLogin(false);
  mainStore.setVisibleSignUp(true);
}
function onForgot() {
  mainStore.setVisibleLogin(false);
  mainStore.setVisibleForgot(true);
}
function submit() {
  if (disable.value) {
    return;
  }
  switch (verifyEmail(form.value.email)) {
    case -1:
    // error.value.email = t("login-tip-empty-pw");
    // return;
    case -2:
      error.value.email = t("login-tip-invalid-email");
      return;
    default:
      error.value.email = "";
      break;
  }
  if (!form.value.password.trim()) {
    error.value.password = t("login-tip-empty-pw");
    return;
  }
  error.value.password = "";
  if (loading.value) {
    return;
  }
  // console.log(values);
  loading.value = true;
  $encryptor.setPublicKey(publicKey);
  const pwd = $encryptor.encrypt(form.value.password);
  useApi
    .loginByEmail({ username: form.value.email, password: pwd })
    .then((res) => {
      const { data, code } = res;

      if (code === 0) {
        useLogEvent({
          eventName: "login-success",
          eventParams: { type: "email" },
        });
        useFBLogEvent({
          eventName: "login-success",
          eventParams: { type: "email" },
        });
        localStorage.setItem("token", data.token);
        localStorage.setItem("userInfo", JSON.stringify(data.user));
        messageBox.success(t("login"), t("login-success"));
        mainStore.setUserInfo(data.user);
        const visitorLoginEvent = new CustomEvent("visitorLoginEvent", {
          detail: data.user,
        });
        window.dispatchEvent(visitorLoginEvent);
        mainStore.setVisibleLogin(false);
        // mainStore.setVisibleLogin(false);
        //如果会员页过来登录的，登录完，非会员返回会员页
        if (mainStore.vipToLogin && data.user.memberType == 0) {
          mainStore.setVisibleVipDialog(true);
        }

        mainStore.setVipToLogin(false);
      }
      loading.value = false;
    })
    .catch((e) => {
      loading.value = false;
      console.log("登录异常:", e);
      useLogEvent({ eventName: "login-fail", eventParams: { type: "email" } });
      useFBLogEvent({
        eventName: "login-fail",
        eventParams: { type: "email" },
      });
      messageBox.error(t("login"), t("login-err"));
    });
}
function onClose() {
  useLogEvent({ eventName: "login-close" });
  useFBLogEvent({ eventName: "login-close" });
  mainStore.setVisibleLogin(false);
  if (mainStore.vipToLogin) {
    mainStore.setVisibleVipDialog(true);
    mainStore.setVipToLogin(false);
  }
}
function loginByGoogle() {
  console.log("Google login");
  useLogEvent({
    eventName: "login",
    eventParams: { method: "Google" },
    needPrefix: false,
  });
  useFBLogEvent({
    eventName: "Login",
    eventParams: { method: "Google" },
    needPrefix: false,
  });
  fullLoading.show(t("login-loading"));
  const provider = new GoogleAuthProvider();
  provider.addScope("https://www.googleapis.com/auth/userinfo.email");
  provider.addScope("https://www.googleapis.com/auth/userinfo.profile");
  const auth = getAuth($firebaseApp);
  signInWithPopup(auth, provider, browserPopupRedirectResolver)
    .then((result) => {
      const idToken = result._tokenResponse.idToken;
      login(LoginType.google, idToken);
    })
    .catch((error) => {
      const errorCode = error.code;
      console.log("error", error);
      errorCodeMsg(errorCode, LoginType.google);
    });
}
function loginByApple() {
  console.log("apple login");
  useLogEvent({
    eventName: "login",
    eventParams: { method: "Apple" },
    needPrefix: false,
  });
  useFBLogEvent({
    eventName: "Login",
    eventParams: { method: "Apple" },
    needPrefix: false,
  });
  fullLoading.show(t("login-loading"));

  const provider = new OAuthProvider("apple.com");
  provider.addScope("email");
  provider.addScope("name");
  const auth = getAuth($firebaseApp);
  signInWithPopup(auth, provider, browserPopupRedirectResolver)
    .then((result) => {
      // The signed-in user info.
      const user = result.user;

      login(LoginType.apple, user.accessToken);
      // ...
    })
    .catch((error) => {
      console.log("error:", error);
      try {
        const errorCode = error.code;
        errorCodeMsg(errorCode, LoginType.apple);
      } catch (e) {}
      // Handle Errors here.

      // ...
    });
  // }
}
function loginFacebook() {
  console.log("Facebook login");
  useLogEvent({
    eventName: "login",
    eventParams: { method: "Facebook" },
    needPrefix: false,
  });
  useFBLogEvent({
    eventName: "login",
    eventParams: { method: "Facebook" },
    needPrefix: false,
  });
  fullLoading.show(t("login-loading"));

  var provider = new FacebookAuthProvider();
  provider.addScope("email");
  provider.addScope("public_profile");
  const auth = getAuth($firebaseApp);

  signInWithPopup(auth, provider, browserPopupRedirectResolver)
    .then((result) => {
      // The signed-in user info.
      var user = result.user;

      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var accessToken = user.accessToken;
      console.log("user result", result);
      login(LoginType.facebook, accessToken);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      // var email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      // var credential = error.credential;
      console.log("errorCode", errorCode);
      console.log("message", errorMessage);
      console.log("credential", error.credential);
      if (errorCode === "auth/account-exists-with-different-credential") {
        console.log(1211);
        errorCodeMsg(errorCode, LoginType.facebook);
      } else {
        errorCodeMsg(errorCode, LoginType.facebook);
      }
      // ...
    });
  // }
}
// function loginEmail() {
//   mainStore.setVisibleLogin(false);
//   mainStore.setVisibleEmailLogin(true);
// }
function errorCodeMsg(errorCode, platform) {
  useLogEvent({
    eventName: "login-catch",
    eventParams: { platform, errorCode },
  });
  useFBLogEvent({
    eventName: "login-catch",
    eventParams: { platform, errorCode },
  });
  switch (errorCode) {
    case "auth/network-request-failed":
    case "auth/internal-error":
      messageBox.error(t("login"), t("network-err-try"));

      break;
    case "auth/account-exists-with-different-credential":
      messageBox.error(t("login"), t("different-auth"));

      break;
    case "auth/popup-closed-by-user":
      // messageBox.error(
      //   t("header-login"),
      //   t("different-auth")
      // );
      break;
    case "auth/popup-blocked":
      console.log("重定向登录");
      messageBox.error(t("login"), t("login-blocked-err"));
      break;
    default:
      messageBox.error(t("login"), t("login-err"));

      break;
  }
  fullLoading.hide();
}
function login(type, token) {
  useApi
    .login({ idTokenString: token })
    .then((res) => {
      const { data, code } = res;

      if (code === 0) {
        useLogEvent({ eventName: "login-success", eventParams: { type } });
        useFBLogEvent({ eventName: "login-success", eventParams: { type } });
        localStorage.setItem("token", data.token);
        localStorage.setItem("userInfo", JSON.stringify(data.user));
        mainStore.setUserInfo(data.user);
        // messageBox.success(t("login-success"));
        messageBox.success(t("login"), t("login-success"));
        const visitorLoginEvent = new CustomEvent("visitorLoginEvent", {
          detail: data.user,
        });
        window.dispatchEvent(visitorLoginEvent);
        mainStore.setVisibleLogin(false);
        //如果会员页过来登录的，登录完，非会员返回会员页
        if (mainStore.vipToLogin && data.user.memberType == 0) {
          mainStore.setVisibleVipDialog(true);
        }
        mainStore.setVipToLogin(false);
      }
      fullLoading.hide();
    })
    .catch((e) => {
      console.log("登录异常:", e);
      fullLoading.hide();
      useLogEvent({ eventName: "login-fail", eventParams: { type } });
      useFBLogEvent({ eventName: "login-fail", eventParams: { type } });
      messageBox.error(t("login"), t("login-err"));
    });
}
</script>

<style scoped lang="scss">
@import "~/assets/css/iv-form.scss";
</style>
