<template>
  <div class="columns-1 gap-3 sm:columns-2 md:columns-3 sm:gap-5">
    <div class="w-full border bg-white border-gray-line p-[20px] rounded-[20px] mb-5 lg:p-[30px]" v-for="(item,index) in list" :key="index" :class="index > 3?'hidden sm:inline-block':'inline-block'">
      <div class="flex justify-between mb-5">
        <div class="flex gap-1">
          <svg
            v-for="i in [0, 1, 2, 3, 4]"
            :key="i"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.1033 1.81699C12.4701 1.07374 13.5299 1.07374 13.8967 1.81699L16.5879 7.26995C16.7336 7.56509 17.0152 7.76966 17.3409 7.81699L23.3586 8.69141C24.1788 8.8106 24.5063 9.81857 23.9128 10.3971L19.5583 14.6416C19.3227 14.8714 19.2151 15.2024 19.2707 15.5268L20.2987 21.5202C20.4388 22.3371 19.5814 22.96 18.8477 22.5743L13.4653 19.7446C13.174 19.5915 12.826 19.5915 12.5347 19.7446L7.15226 22.5743C6.41864 22.96 5.5612 22.3371 5.70131 21.5202L6.72926 15.5268C6.7849 15.2024 6.67735 14.8714 6.44166 14.6416L2.08721 10.3971C1.4937 9.81857 1.82121 8.8106 2.64143 8.69141L8.65913 7.81699C8.98484 7.76966 9.2664 7.56509 9.41207 7.26995L12.1033 1.81699Z"
              fill="#FF9759"
            />
          </svg>
        </div>
        <span class="text-sm text-text-gray">{{
          item.device === 'ios' ? "iOS user" : "Android user"
        }}</span>
      </div>
      <p class=" text-base">{{ item.review }}</p>
    </div>
  </div>
</template>

<script setup>
const list = [
    {start: 5,device: 'ios',review:`Never seen such a high-quality app that is free. I’m totally in love, it is easy to access and fun. I'll definitely recommend this app to everyone; it's the go-to app perfect for making your pictures high quality!! 💕`},
    {start: 5,device: 'android',review:`Don't think I've seen a better AI powered photo editing app. That's usable, howbeit with ads. Awesome job guys.`},
    {start: 5,device: 'ios',review:`This app does wonders! Ideal for dark photos. Doesn’t distort the look of the face. I love that it now has the option to choose between the original color and the auto color. Highly recommended!`},
    {start: 5,device: 'ios',review:`This app is amazing. I had an old photo of my grandmother when she was about 25 years old, and Remini has dramatically improved that. It was the only photo I had of her when she was young, and I'm delighted.`},
    {start: 5,device: 'android',review:`I have tried many AI photo enhancement applications. This one is the best. The result is very natural and realistic and very high quality. Other apps give paint-like results. Remini is perfect.`},
    {start: 5,device: 'ios',review:`This is really amazing app. Looking at the old photos, it was difficult to identify who is in the album, the result is astonishing, and fine details have also been shown. `},
    {start: 5,device: 'ios',review:`IT’S ABSOLUTE MAGIC!`},
    {start: 5,device: 'android',review:`Where there is blur or something, Remini is the quickest: you can do things that take me hours on Photoshop.`},
    {start: 5,device: 'android',review:`I have taken the premier membership of this app. Honestly it's a great app! I can definitely say from my experience that this app will benefit everyone. This app is especially good for those who can't do photo editing. All amazing paid features can be enjoyed for free in return for viewing ads. I really like this app. You can try it, hope you will have a great experience.`},
]
</script>
